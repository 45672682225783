import AOS from 'aos';
const moment = require('moment');
/**
 * 単純なサンプル
 * moment.jsを使って現在日付をconsole出力するだけ
 */
$(function () {
    AOS.init();
    //console.log(moment().format("YYYY-MM-DD"));
});

$(window).on('load resize', function () {
    $(window).on('load scroll', function () {
        var $winTop = $(window).scrollTop();
        var $target = $('.Experience');
        var $winWidth = $(window).width();
        $target.each(function (index) {
            var $position = $winTop - $target.eq(index).offset().top;
            if ($winTop > $target.eq(index).offset().top - 1000) {
                $target.eq(index).css({
                    'background-position-y': $position * .4
                });
            }
        });
    });
});
