// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性



$(document).on("click", "#SPNavOpen", function () {
  $(this).toggleClass('active');
  $("#SPNav").toggleClass('active');
  return false;
})

$(document).on("click", "#SPNavClose", function () {
  $("#SPNavOpen").toggleClass('active');
  $("#SPNav").toggleClass('active');
  return false;
})

$(document).on("click", "#SPNav a", function () {
  $("#SPNavOpen").removeClass('active');
  $("#SPNav").removeClass('active');
  var speed = 500;
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $('body,html').animate({ scrollTop: position }, speed, 'swing');
  return false;
})

// ページトップ
var $pagetop = $('.SideNav');
pagetop_offset = 50;
$pagetop.on('click', function (e) {
  e.preventDefault();
  var _dest = $($(this).find('a').attr('href')).offset().top;
  $('html, body').animate({
    scrollTop: _dest
  }, 1200, 'easeInOutQuart');
});
$(window).on('scroll', function () {
  if ($(this).scrollTop() > pagetop_offset) {
    $pagetop.css('visibility', 'visible').addClass('visible');
  } else {
    $pagetop.removeClass('visible');
  }
});
